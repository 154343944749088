import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Wrapper = styled.div`

`

const DrawerCover = styled.label`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, .5);
`

const Content = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 80%;
  max-width: 330px;
  height: 100%;
  z-index: 999;
  transition: 0.3s ease-in-out;
  background: ${({ theme }) => theme.background.paper} !important;
  ${({ theme }) => theme.dp.twelve};
`

const ItemName = styled.span`
  display: block;
  padding: 10px 20px;
  background: ${({ theme }) => theme.bottomMenu.drawerItemBackground};
  color: ${({ theme }) => theme.bottomMenu.drawerItemName};
  font-size: 35px;
  text-align: center;
  user-select: none;
`

const CategoryWrapper = styled.div`

`

const CategoryItems = styled.ul`
  margin: 10px;
  font-size: 20px;
  line-height: 1.5;
  a {
    margin-bottom: 5px;
    color: ${({ theme }) => theme.text.primary};
    text-decoration: none;
  }
`

const CategoryItem = styled.li`
  margin: 10px;
`

const CategoryItemSub = styled.ul`
  margin-left: 20px;
`

const Drawer = ({ isOpenDrawer, handleIsOpenDrawer }) => {
  return (
    <Wrapper>
      <DrawerCover id="DrawerCover" htmlFor="DrawerContent" onClick={handleIsOpenDrawer} style={{ transform: `${isOpenDrawer ? `translateX(0%)` : `translateX(-100%)`}` }} />
      <Content id="DrawerContent" style={{ transform: `${isOpenDrawer ? `translateX(0%)` : `translateX(-100%)`}` }}>
        <ItemName>カテゴリー</ItemName>
        <CategoryWrapper>
          <CategoryItems>
            <CategoryItem>
              <Link to="/category/code/">コード</Link>
              <CategoryItemSub className="sub-menu">
                <li><Link to="/category/code/gatsby/">Gatsby</Link></li>
                {/* <li><Link to="/category/code/firebase/">Firebase</Link></li> */}
              </CategoryItemSub>
            </CategoryItem>
            <CategoryItem>
              <Link to="/category/dev/">開発</Link>
            </CategoryItem>
          </CategoryItems>
        </CategoryWrapper>
      </Content>
    </Wrapper>
  )
}

export default Drawer