import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import CategoryMenu from "@components/CategoryMenu"


const Wrapper = styled.header`
  z-index: 99;
	${({ theme }) => theme.header.background};
	user-select: none;
`

const HeaderMenu = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
`

const Title = styled.span`
  font-size: 35px;
  a {
    color: ${({ theme }) => theme.header.text};
    text-decoration: none;
	}
  @media screen and (max-width: 600px) {
    font-size: 25px;
    vertical-align: middle;
  }
`

const ThemeChanger = styled.div`
  input {
  	display: none;
    margin: auto 0;
    :checked + .isDark {
      background: ${({ theme }) => theme.background.primary};
      .sum {
        transform: translateY(-35px);
        z-index: -10;
      }
      .moon {
        display: block;
        transform: translateY(0px);
        z-index: 0;
      }
    }
	}
  .isDark {
		position: relative;
    display: inline-block;
   	width: 40px;
    height: 40px;
		border-radius: 40px;
    background: rgb(46, 46, 46);
		cursor: pointer;
  }
	.moon, .sum {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 20px;
		cursor: pointer;
    user-select: none;
    transition: all .3s ease;
  }
  .sum {
    display: block;
    z-index: 0;
  }
  .moon {
    transform: translateY(-35px);
    z-index: -10;
  }
`

const Header = ({ isDark, darkModeHandler }) => {
  return (
    <Wrapper
      isDark={isDark}
    >
      <HeaderMenu>
        <Title>
          <Link to={`/`}>
            sukiburo.dev
          </Link>
        </Title>
        <ThemeChanger>
          <input
            id="isDark"
            type="checkbox"
            checked={isDark}
            onChange={darkModeHandler}
          />
          <label className="isDark" htmlFor="isDark">
            <label className="moon" htmlFor="isDark"><span role="img" aria-label="moon">🌛</span></label>
            <label className="sum" htmlFor="isDark"><span role="img" aria-label="sum">🌞</span></label>
          </label>
        </ThemeChanger>
      </HeaderMenu>
      <CategoryMenu />
    </Wrapper>
  )
}

export default Header