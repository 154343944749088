import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const CategoryWrapper = styled.nav`
	display: grid;
	text-align: center;
	white-space: nowrap;
	@media screen and (max-width: 600px) {
		overflow-x: scroll;
	}
`

const CategoryItems = styled.ul`
	display: grid;
	justify-content: center;
	grid-template-columns: 70px 90px 80px;
`

const CategoryItem = styled.li`
  position: relative;
	display: inline-block;
  a {
    padding: 13px;
		color: ${({ theme }) => theme.text.primary};
		font-size: 15px;
		line-height: 44px;
		text-decoration: none;
	}
  :hover > .sub-menu {
    display: flex;
    flex-direction: column;
		@media screen and (max-width: 600px) {
      display: none;
    }
	}
`

const CategoryItemSub = styled.ul`
	position: absolute;
  display: none;
	min-width: 100px;
  background: ${({ theme }) => theme.background.paper} !important;
  ${({ theme }) => theme.dp.eight};
  li {
    a {
			white-space: nowrap;
     	color: ${({ theme }) => theme.header.subMenuLink};    
	 	}
	}
`

const CategoryMenu = () => {
	return (
		<CategoryWrapper>
			<CategoryItems>
				<CategoryItem>
					<Link key="home" to={`/`}>Home</Link>
				</CategoryItem>
				<CategoryItem>
					<Link to="/category/code/">コード</Link>
					<CategoryItemSub className="sub-menu">
						<li><Link to="/category/code/gatsby/">Gatsby</Link></li>
						{/* <li><Link to="/category/code/firebase/">Firebase</Link></li> */}
					</CategoryItemSub>
				</CategoryItem>
				<CategoryItem>
					<Link to="/category/dev/">開発</Link>
				</CategoryItem>
			</CategoryItems>
		</CategoryWrapper>
	)
}

export default CategoryMenu
