// ref: https://www.gatsbyjs.com/docs/adding-search-with-algolia/#supporting-files

// import styled, { css } from "styled-components"

import styled from "styled-components"
import SearchBox from "./search-box"

// const open = css`
//   width: 10em;
//   background: ${({ theme }) => theme.background};
//   cursor: text;
//   margin-left: -1.6em;
//   padding-left: 1.6em;
// `

// const closed = css`
//   width: 0;
//   background: transparent;
//   cursor: pointer;
//   margin-left: -1em;
//   padding-left: 1em;
// `

export default styled(SearchBox)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 0;
  width: 290px;
  @media screen and (max-width: 950px) {
    display: block;
    width: auto;
    margin: 0 auto 40px;
  }

  .SearchInput {
    width: 100%;
    padding: 11px;
    border: none;
    font-size: 16px;
    border-radius: 2px;
    color: ${({ theme }) => theme.text.primary};
    background: ${({ theme }) => theme.background.paper};
    ${({ theme }) => theme.dp.one};
    outline: none;
    user-select: none;
    caret-color: #ff4081;
    ::placeholder {
      color: ${({ theme }) => theme.faded};
    }
    // ref: line54
  }

  // .SearchIcon {
  //   width: 1em;
  //   margin: 0.3em;
  //   color: ${({ theme }) => theme.foreground};
  //   pointer-events: none;
  // }
`

// line43: ${({ hasFocus }) => (hasFocus ? open : closed)}