import React, { useState } from "react"
import styled, { ThemeProvider } from "styled-components"

import BottomNav from "@components/BottomNavigation"
import Footer from "@components/Footer"
import Head from "@components/Head"
import Header from "@components/Header"
import Sidebar from "@components/SideBar"

import GlobalStyle from "@styles/global"
import { darkTheme, lightTheme } from "@styles/theme"

const ContentWrapper = styled.div`
  display: flex;
  max-width: 1140px;
  margin: 40px auto;
  background: ${({ theme }) => theme.background.primary};
  @media screen and (max-width: 950px) {
    display: block;
    margin: 40px 20px;
  }
  @media screen and (max-width: 600px) {
    margin: 0;
  }
`

const MainWrapper = styled.main`
  display: block;
  width: 100%;
  margin: 0 40px;
  @media screen and (max-width: 950px) {
    max-width: 730px;
    margin: 0 auto 30px;
  }
  @media screen and (max-width: 600px) {
    display: block;
  }
`

const getColorMode = () => {
  if (typeof window === 'undefined') {
    return "light"
  }

  const setTheme = window.localStorage.getItem("theme")
  const hasSetTheme = typeof setTheme === "string"
  // if the user already chosen site theme color
  if (hasSetTheme) {
    return setTheme
  }
  else {
    return "light"
  }
}

const Layout = ({ children }) => {
  const [isDark, setIsDark] = useState(getColorMode)

  const darkModeHandler = () => {
    setIsDark(isDark === "light" ? "dark" : "light")
    localStorage.setItem("theme", isDark === "light" ? "dark" : "light")
  }
  return (
    <ThemeProvider theme={isDark === "dark" ? darkTheme : lightTheme}>
      <Head />
      <Header
        darkModeHandler={darkModeHandler}
        isDark={isDark === "dark"}
      />
      <ContentWrapper>
        <MainWrapper>
          {children}
        </MainWrapper>
        <Sidebar />
        <BottomNav />
      </ContentWrapper>
      <Footer />
      <GlobalStyle />
    </ThemeProvider>
  )
}

export default Layout
