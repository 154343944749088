import React from "react"
import Helmet from "react-helmet"


const Head = () => {
  return (
    <Helmet>
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NQ4SJB5');`}
      </script>
      <link as="font" href="fonts/Rubik/Rubik-Regular.woff2" rel="preload" crossorigin="anonymous" />
      <link as="font" href="fonts/Rubik/Rubik-Bold.woff2" rel="preload" crossorigin="anonymous" />
      <link as="font" href="fonts/SJPF/SJPF-Regular.woff2" rel="preload" crossorigin="anonymous" />
    </Helmet>
  )
}

export default Head