import React from "react"
import styled from "styled-components"

import TOC from "@components/TOC"


const Wrapper = styled.div`

`

const TOCDrawerCover = styled.label`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, .5);
`

const Content = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: 290px;
  z-index: 999;
  transition: 0.3s ease-in-out;
  background: ${({ theme }) => theme.background.paper} !important;
  ${({ theme }) => theme.dp.twelve};
`

const TOCDrawer = ({ isOpenTOCDrawer, handleIsOpenTOCDrawer }) => {
  return (
    <Wrapper>
      <TOCDrawerCover id="TOCDrawerCover" htmlFor="TOCDrawerContent" onClick={handleIsOpenTOCDrawer} style={{ transform: `${isOpenTOCDrawer ? `translateX(0%)` : `translateX(-100%)`}` }} />
      <Content id="TOCDrawerContent" style={{ transform: `${isOpenTOCDrawer ? `translateX(0%)` : `translateX(100%)`}` }}>
        <TOC />
      </Content>
    </Wrapper>
  )
}

export default TOCDrawer