import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"

const SEO = ({
  description,
  lang,
  meta,
  title,
  type,
  url,
  date,
  update,
  image,
  robots }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteName
            siteUrl
            social {
              twitter
            }
          }
        }
      }
    `
  )

  const theme = typeof window === 'undefined' ? "light" : window.localStorage.getItem("theme")
  
  const defaultTitle = site.siteMetadata?.title
  const metaDescription = description || site.siteMetadata.description
  const siteName = site.siteMetadata.siteName
  const siteUrl = site.siteMetadata.siteUrl
  const robotsSetting = robots || `index,follow`

  const ogType = type || "website";
  const ogUrl = `${siteUrl}${url || ""}`;
  
  const ogImage = image !== null
    ? `${siteUrl}${image.src}`
    : `https://res.cloudinary.com/dqi4uoycj/image/upload/l_text:Sawarabi%20Gothic_50:${title},w_600,c_fit,q_auto,f_auto,${theme === "light"
      ? `co_rgb:333/v1609251859/light_post.png`
      : `co_rgb:FFF/v1609251867/dark_post.png`}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      script={type === "article" ?
        [
          {
            type: ["application/ld+json"],
            innerHTML: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Article",
              "headline": title,
              "description": description,
              "image": {
                "@type": "ImageObject",
                "url": ogImage,
                "width": "1350",
                "height": "650"
              },
              "dataPublished": date,
              "dataModified": update,
            })
          },
        ]
        :
        [
          {
            type: ["application/ld+json"],
          }
        ]
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `robots`,
          content: robotsSetting,
        },
        {
          name: "og:locale",
          content: "ja_JP"
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: ogType,
        },
        {
          property: `og:url`,
          content: ogUrl,
        },
        {
          property: `og:site_name`,
          content: siteName
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata?.social?.twitter || ``,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.social?.twitter || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:url`,
          content: ogUrl,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: ogImage,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `ja`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO