import React, { useState, useEffect, useContext, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import AppContext from "@contexts/AppContext"
import { Link } from "gatsby"
import styled from "styled-components"

import Drawer from "@components/Drawer"
import TOCDrawer from "@components/TOCDrawer"

import scrollTo from 'gatsby-plugin-smoothscroll'

const Wrapper = styled.div`
	user-select: none;
`

const MenusWrapper = styled.nav`
  display: none;
  @media screen and (max-width: 950px) {
    display: grid;
  }
`

const Items = styled.ul`
  position: fixed;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  bottom: 0;
  left: 0;
	z-index: 98;
	width: 100%;
  margin: 0;
	${({ theme }) => theme.bottomMenu.drawerBackground};
  transition: 0.3s cubic-bezier(1, 0, 0, 1);
`

const Item = styled.li`
  a {
		display: grid;
		justify-content: center;
		padding: 13px;
		color: ${({ theme }) => theme.text.primary};
		text-decoration: none;
		outline: none;
  	svg {
			margin: 0 auto;
			fill: ${({ theme }) => theme.text.primary};
		}
		span {
			font-size: 10px;
			text-align: center;
		}
	}
`

const BottomNav = () => {
	const data = useStaticQuery(graphql`
    query {
			site {
        siteMetadata {
          siteUrl
          social {
            twitter
          }
        }
      }
      twitter: file(relativePath: {regex: "/socials/twitter/"}) {
        publicURL
			}
			facebook: file(relativePath: {regex: "/socials/facebook/"}) {
        publicURL
			}
			pocket: file(relativePath: {regex: "/socials/pocket/"}) {
        publicURL
			}
			line: file(relativePath: {regex: "/socials/line/"}) {
        publicURL
			}
			hatena: file(relativePath: {regex: "/socials/hatena/"}) {
    		publicURL
  		}
    }
	`)
	const twitter = data.twitter.publicURL
	const facebook = data.facebook.publicURL
	const pocket = data.pocket.publicURL
	const line = data.line.publicURL
	const hatena = data.hatena.publicURL
	
	const value = useContext(AppContext)
	const pageText = `${value.title}`
	const pageUrl = `${data.site.siteMetadata.siteUrl}${value.slug}`
	const twitterId = data.site.siteMetadata.social.twitter

	const [isOpenShare, setIsOpenShare] = useState(false)
	const [isOpenDrawer, setIsOpenDrawer] = useState(false)
	const [isOpenTOCDrawer, setIsOpenTOCDrawer] = useState(false)

	const handleIsOpenShare= () => {
		setIsOpenShare(!isOpenShare)
	}

	const handleIsOpenDrawer = () => {
		setIsOpenDrawer(!isOpenDrawer)
	}

	const handleIsOpenTOCDrawer = () => {
		setIsOpenTOCDrawer(!isOpenTOCDrawer)
	}

	const isRunning = useRef(false)
	const [posY, setPosY] = useState(0)
	const [isUp, setIsUp] = useState(true)

	useEffect(() => {
		document.addEventListener('scroll', handleScroll, { passive: true })
		return () => {
       document.removeEventListener("scroll", handleScroll, { passive: true })
    }
	})

	const handleScroll = () => {
    if (!isRunning) return
		isRunning.current = true
		if (window.pageYOffset < posY) {
			setIsUp(true)
		}
		else {
			setIsUp(false)
		}
		setPosY(window.pageYOffset)
	}
	
	return (
		<Wrapper>
			<MenusWrapper>
				<Items style={{ bottom: `${isUp ? `0px` : `-60px`}` }}>
					<Item>
						<Link key="home" to={`/`}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="24px" height="24px">
								<path d="M0 0h24v24H0z" fill="none" />
								<path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
							</svg>
							<span>ホーム</span>
						</Link>
					</Item>
					<Item>
						<a
							role="link"
							tabIndex="0"
							onClick={handleIsOpenDrawer}
							style={{ cursor: "context-menu" }}
						>
							<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
								<path d="M0 0h24v24H0z" fill="none" />
								<path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
							</svg>
							<span>メニュー</span>
						</a>
					</Item>
					<Item>
						<a
							role="link"
							tabIndex="0"
							onClick={handleIsOpenShare}
							style={{ cursor: "pointer" }}
						>
							<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
								<path d="M0 0h24v24H0z" fill="none" />
								<path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z" />
							</svg>
							<span>シェア</span>
						</a>
					</Item>
					<Item>
						<a
							role="link"
							tabIndex="0"
							onClick={handleIsOpenTOCDrawer}
							style={{ cursor: "context-menu" }}
						>
							<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
								<path d="M0 0h24v24H0z" fill="none" />
								<path d="M3 9h14V7H3v2zm0 4h14v-2H3v2zm0 4h14v-2H3v2zm16 0h2v-2h-2v2zm0-10v2h2V7h-2zm0 6h2v-2h-2v2z" />
							</svg>
							<span>目次</span>
						</a>
					</Item>
					<Item>
						<a
							role="link"
							tabIndex="0"
							onClick={() => scrollTo('html')}
							style={{ cursor: "pointer" }}
						>
							<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
								<path d="M0 0h24v24H0z" fill="none" />
								<path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
							</svg>
							<span>トップ</span>
						</a>
					</Item>
				</Items>
			</MenusWrapper>
			<MenusWrapper style={{ bottom: `${isOpenShare && isUp ? `60px` : `-65px`}` }}>
				<Items style={{ bottom: `${isOpenShare && isUp ? `60px` : `-65px`}`, zIndex: "97" }}>
					<Item>
						<a
							href={`https://b.hatena.ne.jp/add?&url=${pageUrl}`}
							rel="nofollow noopener noreferrer"
							target="_blank"
							style={{ color: "rgb(72, 163, 217)" }}
							aria-label="b!"
						>
							<img src={hatena} width="30" height="30" alt="hatena" />
						</a>
					</Item>
					<Item>
						<a
							href={`https://getpocket.com/edit?url=${pageUrl}`}
							rel="nofollow noopener noreferrer"
							target="_blank"
							aria-label="pocket"
						>
							<img src={pocket} width="30" height="30" alt="pocket" />
						</a>
					</Item>
					<Item>
						<a
							href={`https://twitter.com/share?text=${pageText}&url=${pageUrl}&via=${twitterId}`}
							rel="nofollow noopener noreferrer"
							target="_blank"
							aria-label="twitter"
						>
							<img src={twitter} width="30" height="30" alt="twitter" />
						</a>
					</Item>
					<Item>
						<a
							href={`https://social-plugins.line.me/lineit/share?url=${pageUrl}`}
							rel="nofollow noopener noreferrer"
							target="_blank"
							aria-label="line"
						>
							<img src={line} width="30" height="30" alt="line" />
						</a>
					</Item>
					<Item>
						<a
							href={`https://www.facebook.com/share.php?u=${pageUrl}`}
							rel="nofollow noopener noreferrer"
							target="_blank"
							aria-label="facebook"
						>
							<img src={facebook} width="30" height="30" alt="facebook" />
						</a>
					</Item>
				</Items>
			</MenusWrapper>
			<Drawer
				isOpenDrawer={isOpenDrawer}
				handleIsOpenDrawer={handleIsOpenDrawer}
			/>
			<TOCDrawer
				isOpenTOCDrawer={isOpenTOCDrawer}
				handleIsOpenTOCDrawer={handleIsOpenTOCDrawer}
			/>
		</Wrapper>
	)
}

export default BottomNav