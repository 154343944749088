import React, { useContext } from "react"
import AppContext from "@contexts/AppContext"
import styled from "styled-components"

import Bio from "@components/Bio"
import Search from "@components/search/default"
import TOC from "@components/TOC"

const searchIndices = [{ name: `Pages`, title: `Pages` }]

const Wrapper = styled.aside`
  margin: 0 40px 0 0;
  @media screen and (max-width: 950px) {
    display: block;
    width: auto;
    max-width: 730px;
    min-width: 0;
    margin: 0 auto 40px;
  }
`

const TOCWrapper = styled.div`
  @media screen and (max-width: 950px) {
    display: none;
  }
`

const AfWrapper = styled.div`
  img {
    width: 100%;
    height: auto;
  }
`

const FixedWidget = () => {
  const value = useContext(AppContext)
  const af = value.af === "dummy" ? null  : value.af || null
  // const af_html = af === null ? null : require(`@static/affiliate/${af}.html`)
  const af_html = null
  return (
    <div style={{ position: "sticky", top: "40px" }}>
      <div style={{ marginBottom: "40px" }}>
        <TOCWrapper style={{ width: "290px", marginBottom: "40px" }}>
          <TOC />
        </TOCWrapper>
        {af_html !== null
          ?
          <AfWrapper
            style={{ width: "290px" }}
            dangerouslySetInnerHTML={{ __html: af_html }}
          />
          :
          null
        }
      </div>
    </div>
  )
}

const Sidebar = () => {
  return (
    <Wrapper>
      <Search
        indices={searchIndices}
      />
      <Bio />
      <FixedWidget />
    </Wrapper>
  )
}

export default Sidebar