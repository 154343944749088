import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"


const Wrapper = styled.div`
  width: 290px;
  min-width: 290px;
  margin-bottom: 40px;
  padding: 15px;
  border-radius: 2px;
  background: ${({ theme }) => theme.background.paper};
  ${({ theme }) => theme.dp.one};
  @media screen and (max-width: 950px) {
    display: block;
    width: auto;
    margin: 0 auto 40px;
  }
  @media screen and (max-width: 330px) {
    min-width: 0px;
  }
`

const BioImage = styled.div`
  width: 150px;
  margin: 0px auto 20px;
  user-select: none;
  pointer-events: none;
`

const Main = styled.div`
  margin: 0 30px 30px;
`

const Name = styled.span`
  display: block;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text.primary};
  font-size: 25px;
  text-align: center;
  cursor: default;
`

const Content = styled.div`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text.primary};
  cursor: default;
  span {
    display: inline-block;
    line-height: 1.3em;
    margin-bottom: 10px;
  }
`

const Socials = styled.div`
  display: grid;
  justify-content: center;
  margin-bottom: 20px;
`

const  Social = styled.a`
  /* font-size: 30px; */
`

const Profile = styled.div`
  text-align: center;
  a {
    color: ${({ theme }) => theme.text.primary};
    text-decoration: none;
  }
`

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
          }
          social {
            twitter
          }
        }
      }
      image: file(relativePath: {regex: "/icon/icon.png/"}) {
        childImageSharp {
          fixed (width: 150, height: 150){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      twitter: file(relativePath: {regex: "/socials/twitter/"}) {
        publicURL
      }
    }
  `)

  const author = data.site.siteMetadata.author.name
  const twitterId = data.site.siteMetadata.social.twitter
  const bioImage = data.image.childImageSharp.fixed
  const twitter = data.twitter.publicURL

  return (
    <Wrapper>
      <BioImage>
        <Img
          fixed={bioImage}
          alt={author}
          aria-label={author}
          style={{ borderRadius: "50%" }}
        />
      </BioImage>
      <Main>
        <Name>
          {author}
        </Name>
        <Content>
          <span>どうも、ゆうです。</span>
          <span>gatsbyでサイト作ってみました。</span>
        </Content>
        <Socials>
          <Social
            href={`https://twitter.com/${twitterId}`}
            rel="nofollow noopener noreferrer"
            target="_blank"
            aria-label="twitter"
          >
            <img src={twitter} width="30" height="30" alt="twitter" />
          </Social>
        </Socials>
        <Profile>
          <a href="profile/">
            詳細プロフィール
          </a>
        </Profile>
      </Main>
    </Wrapper>
  )
}

export default Bio