import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
	  padding: 0;
	  border: 0;
	  font-size: 100%;
	  font: inherit;
    vertical-align: baseline;
  }
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
	  display: block;
  }
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  html, body {
    line-height: 1;
    background: ${({ theme }) => theme.background.default};
    color: ${({ theme }) => theme.text.post};
    font-family: Rubik, "SJPF" ,Arial, sans-serif;
  }
  ol, ul {
	  list-style: none;
  }
  blockquote, q {
	  quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
	  content: '';
	  content: none;
  }
  table {
	  border-collapse: collapse;
	  border-spacing: 0;
  }
  ::selection {
    background: rgba(33,150,243,.3);
  }
  .medium-zoom-overlay {
    background: rgba(0, 0, 0, .85) !important;
  }
`

export default GlobalStyle