import React, { useContext } from "react"
import AppContext from "@contexts/AppContext"
import styled from "styled-components"

import scrollTo from 'gatsby-plugin-smoothscroll'


const Wrapper = styled.div`
  /* width: 290px; */
  min-width: 290px;
  max-height: 360px;
  /* margin-bottom: 40px; */
  padding: 10px 0px 10px 10px;
  border-radius: 2px;
  background: ${({ theme }) => theme.background.paper};
  ${({ theme }) => theme.dp.one};
`

const Title = styled.p`
  padding: 10px 10px 10px 0px;
  color: ${({ theme }) => theme.text.post};
  font-size: 20px;
  text-align: center;
  cursor: default;
`

const Content = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 7.5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbar.thumbBackground};
    border-radius: 10px;
  }
  #toc_list {
    margin: 0 20px;
    padding: 10px;
    font-size: 14px;
    counter-reset: number 0;
    a {
      font-size: 14px;
      line-height: 2;
      color: ${({ theme }) => theme.text.post};
      text-decoration: none;
      cursor: pointer;
      :hover{
        text-decoration: underline;
      }
    }
    span {
      padding: 3px 0px;
      font-size: 0.85em;
      line-height: 1.5;
    }
    & > li {
      list-style: decimal;
      &::marker {
        color: #2196f3;
        font-size: 1.5em;
      }
      & > ol > li {
        margin-left: 1em;
        & > ol > li {
          margin-left: 1em;
        }
      }
    }
  }
`

const TOCList = ({toc, isFirst}) => {
  return (
    <ol id={isFirst && "toc_list"}>
      {toc.map(c => (
        <li
          key={c.title}
        >
          <span>
            <a
              role="link"
              onClick={() => scrollTo(c.url)}>
              {c.title}
            </a>
          </span>
          {c.items && (
            <TOCList toc={c.items} />
          )}
        </li>
      ))}
    </ol>
  )
}

const TOC = () => {
  const value = useContext(AppContext)
  const TOC = value.TOC || {}

  if (Object.keys(TOC).length === 0) {
    return (
      null
    )
  }

  return (
    <div id="toc">
      <Wrapper>
        <Title>
          目次
          </Title>
        <Content>
          <TOCList toc={TOC.items} isFirst={true} />
        </Content>
      </Wrapper>
    </div>
  )
}

export default TOC