import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"


const Wrapper = styled.footer`
  display: block;
	${({ theme }) => theme.footer.background};
`

const Nav = styled.nav`
	display: grid;
	text-align: center;
	@media screen and (max-width: 600px) {
		overflow-x: scroll;
	}
`

const Menus = styled.ul`
  display: grid;
	justify-content: center;
	grid-template-columns: 120px 180px 60px 140px;
  a {
		padding: 13px;
		color: ${({ theme }) => theme.text.primary};
		font-size: 15px;
		line-height: 60px;
    text-decoration: none;
	}
`

const Copyright = styled.div`
	display: grid;
	text-align: center;
	white-space: nowrap;
	@media screen and (max-width: 600px) {
		overflow-x: scroll;
	}
	ul {
		display: grid;
		justify-content: center;
		li {
			display: inline-block;
			p {
				padding: 13px;
				color: ${({ theme }) => theme.text.primary};
				font-size: 15px;
				font-family: Arial;
    		text-decoration: none;
			}
		}
	}
`

const Footer = () => {
	return (
		<Wrapper>
			<Nav>
				<Menus>
					<li><Link to="/profile/">プロフィール</Link></li>
					<li><Link to="/privacy-policy/">プライバシーポリシー</Link></li>
					<li><Link to="/oss/">OSS</Link></li>
					<li><Link to="/contact/">問い合わせ</Link></li>
				</Menus>
			</Nav>
			<Copyright>
				<ul>
					<li>
						<p>Copyright - yuu 2020 All Rights Reserved.</p>
					</li>
				</ul>
			</Copyright>
		</Wrapper>
	)
}

export default Footer