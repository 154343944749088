const primaryColor = {
  light: "#4dabf5",
  main: "#2196f3",
  dark: "#1769aa",
  contrastText: "#fff"
}

const secondaryColor = {
  light: "#ff4081",
  main: "#f50057",
  dark: "#c51162",
  contrastText: "#fff"
}

export const darkTheme = {
  dp: {
    zero: "background: rgba(255, 255, 255, .00);",
    one: "background: rgba(255, 255, 255, .05);",
    two: "background: rgba(255, 255, 255, .07);",
    three: "background: rgba(255, 255, 255, .08);",
    four: "background: rgba(255, 255, 255, .09);",
    six: "background: rgba(255, 255, 255, .11);",
    eight: "background: rgba(255, 255, 255, .12);",
    twelve: "background: rgba(255, 255, 255, .14);",
    sixteen: "background: rgba(255, 255, 255, .15);",
    twentyfour: "background: rgba(255, 255, 255, .16);",
  },
  background: {
    paper: "rgb(66, 66, 66)",
    default: "rgb(18, 18, 18)",
    primary: "rgb(18, 18, 18)",
    secondary: "rgb(27,34,60);",
    tertiary: "rgb(58, 58, 60)",
  },
  primary: primaryColor,
  secondary: secondaryColor,
  text: {
    post :"rgb(255, 255, 255, .88)",
    primary: "#fff",
    secondary: "rgba(255, 255, 255, .7)",
    disabled: "rgba(255, 255, 255, .5)",
    hint: "rgba(255, 255, 255, .5)",
    divider: "rgba(255, 255, 255, .12)",
  },
  emphasis: {
    background: "rgb(36, 36, 36)",
    text: "rgb(187, 187, 187)",
  },
  error: {
    background: "rgb(44, 26, 25)",
    mark: "#f44336",
    text: "rgb(250, 179, 174)",
    contrastText: "",
  },
  warning: {
    background: "rgb(45, 35, 20)",
    mark: "#ff9800",
    text: "rgb(255, 213, 153)",
    contractText: "",
  },
  info: {
    background: "rgb(23, 34, 44)",
    mark: `${primaryColor.main}`,
    text: "rgb(166, 213, 250)",
    contrastText: "",
  },
  success: {
    background: "rgb(27, 37, 27)",
    mark: "#4caf50",
    text: "rgb(183, 223, 185)",
    contrastText: "",
  },
  code: {
    background: "rgb(205,210,220)",
    text: "rgb(32, 32, 32)",
  },
  blockquote: {
    background: "rgb(36, 36, 36)",
    text: "rgb(187, 187, 187)",
  },
  img: {
    filter: "filter: opacity(1);"
  },
  header: {
    text: "rgb(199, 199, 204)",
    background: "background: rgba(255, 255, 255, .09);",
    subMenuLink: "rgb(199, 199, 204)",
  },
  bottomMenu: {
    shareBackground: "background: rgb(39, 39, 39);",
    drawerItemName: "rgb(199, 199, 204)",
    drawerItemBackground: "rgba(255, 255, 255, .09);",
    drawerBackground: "background: rgb(39, 39, 39);"
  },
  footer: {
    // text: "rgb(199, 199, 204)",
    background: "background: rgba(255, 255, 255, .09);",
  },
  postCard: {
    boxShadow: "rgb(33 35 37)",
    categoryImageBackground: "rgb(30, 30, 30)"
  },
  Assist: {
    background: "rgb(30, 30, 30)"
  },
  ImgTxt: {
    border: "rgb(66, 66, 66)",
    before: "rgb(66, 66, 66)",
    background: "rgb(0, 0, 0, 0)",
  },
  talkBox: {
    border: "rgb(42, 42, 42)",
    before: "rgb(42, 42, 42)",
    after: "rgb(42, 42, 42)",
    background: "rgb(42, 42, 42)",
  },
  scrollbar: {
    trackBackground: "rgba(255, 255, 255, .125)",
    thumbBackground: "rgba(255, 255, 255, .95)"
  },
  KbdKey: {
    background: "rgb(57, 57, 57)",
    border: "rgb(36, 36, 36)",
  },
  KbdMenu: {
    background: "rgb(57, 57, 57)",
  },
  ChangeLog: {
    background: "rgb(46, 46, 46)",
    text: "rgb(187, 187, 187)",
  },
  Marker: {
    color: "linear-gradient(transparent 80%,rgb(255, 64, 129, .75) 80%)",
    fontWeight: "normal",
  },
}

export const lightTheme = {
  dp: {
    zero: "box-shadow: none;",
    one: "box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20);",
    two: "box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20);",
    three: "box-shadow: 0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20);",
    four: "box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);",
    six: "box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20);",
    eight: "box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);",
    twelve: "box-shadow: 0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20);",
    sixteen: "box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.20);",
    twentyfour: "box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.20);",
  },
  background: {
    paper: "#fff",
    default: "rgb(230, 235, 245)",
    primary: "rgb(230, 235, 245)",
    secondary: "white",
    tertiary: "rgb(199, 199, 204)",
  },
  primary: primaryColor,
  secondary: secondaryColor,
  text: {
    post :"#333",
    primary: "rgba(0, 0, 0, .87)",
    secondary: "rgba(0 ,0, 0, .54)",
    disabled: "rgba(0 ,0, 0, .38)",
    hint: "rgba(0 ,0, 0, .38)",
    divider: "rgba(0 ,0, 0, .12)",
  },
  emphasis: {
    background: "rgb(239, 239, 239)",
    text: "rgb(68, 68, 68)",
  },
  error: {
    background: "rgb(253, 236, 234)",
    mark: "#f44336",
    text: "rgb(97, 26, 21)",
    contrastText: "",
  },
  warning: {
    background: "rgb(255, 244, 229)",
    mark: "#ff9800",
    text: "rgb(102, 60, 0)",
    contrastText: "",
  },
  info: {
    background: "rgb(232, 244, 253)",
    mark: "rgb(31 143 232)",
    text: "rgb(13, 60, 97)",
    contrastText: "",
  },
  success: {
    background: "rgb(237, 247, 237)",
    mark: "#4caf50",
    text: "rgb(30, 70, 32)",
    contrastText: "",
  },
  code: {
    background: "rgb(235,240,250)",
    text: "rgb(72, 72, 72)",
  },
  blockquote: {
    background: "rgb(239, 239, 239)",
    text: "rgb(68, 68, 68)",
  },
  img: {
    filter: ""
  },
  header: {
    text: "white",
    background: `background: ${primaryColor.main}; box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);`,
    subMenuLink: "black",
  },
  bottomMenu: {
    shareBackground: "background: #fff; box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);",
    drawerItemName: "white",
    drawerItemBackground: `${primaryColor.light};`,
    drawerBackground: "background: #fff; box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.20);",
  },
  footer: {
    // text: "white",
    background: `background: ${primaryColor.main}; box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);`,
  },
  postCard: {
    boxShadow: "rgba(0, 0, 0, 0.1)",
    categoryImageBackground: "white"
  },
  Assist: {
    background: "white"
  },
  ImgTxt: {
    border: "#ccc",
    before: "#ccc",
    background: "white",
  },
  talkBox: {
    border: "#ccc",
    before: "#ccc",
    after: "#fff",
    background: "white",
  },
  scrollbar: {
    trackBackground: "rgba(0, 0, 0, .1)",
    thumbBackground: "rgba(0, 0, 0, .5)"
  },
  KbdKey: {
    background: "rgb(224, 224, 224)",
    border: "rgb(180, 180, 180)",
  },
  KbdMenu: {
    background: "rgb(239, 239, 239)",
  },
  ChangeLog: {
    background: "rgb(239, 239, 239)",
    text: "rgb(68, 68, 68)",
  },
  Marker: {
    color: "linear-gradient(transparent 80%,rgb(255,64,129,.5) 80%)",
    fontWeight: "bold",
  },
}